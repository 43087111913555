import React, { useEffect, useState } from "react";
import { neumaticoGuardarPost, neumaticoPorId } from "../../services/neumaticos";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import Footer from "../../component/Footer";
import useUsuario from "../../hooks/useUsuario";

export default function RegistrarNeumatico(){
    const navigate = useNavigate();
    const[params] = useSearchParams();
    const {usuarioctx} = useUsuario();

    const [idneumatico,setIdNeumatico] = useState(params.get('idneumatico')?? 0);
    const [codigo,setCodigo] = useState('');
    const [marca,setMarca] = useState('');
    const [disenio,setDisenio] = useState('');
    const [medida,setMedida] = useState('');
    //const [remanente,setRemanente] = useState('');
    const [condicion,setCondicion] = useState(1);
    const [fecharegistro,setFechaRegistro] = useState('');
    const [rem1,setRem1] = useState(0);
    const [rem2,setRem2] = useState(0);
    const [rem3,setRem3] = useState(0);

    const [serie,setSerie] = useState('');
    const [dot,setDot] = useState('');
    const [reencauche,setReencauche] = useState(0);
    const [proveedor,setProveedor] = useState('');
    const [costo,setCosto] = useState(0);
    const [ordencompra,setOrdenCompra] = useState('');
    const [modeloreencauche,setModeloReencauche] = useState('');
    const [anchobanda,setAnchoBanda] = useState('');

    const [presion,setPresion] = useState(0);
    const [tapa,setTapa] = useState(1);
    const [observacion,setObservacion] = useState('');
    const [historial,setHistorial] = useState([]);

    const [validar,setValidar] = useState(true);
    const [validarcodigo,setValidarCodigo] = useState('');


    const onCodigoChange = e =>setCodigo(e.target.value);
    const onMarcaChange = e =>setMarca(e.target.value);
    const onDisenioChange = e =>setDisenio(e.target.value);
    const onMedidaChange = e => setMedida(e.target.value);
    //const onRemanenteChange = e => setRemanente(e.target.value);
    const onCondicionChange = e => setCondicion(e.target.value);
    const onFechaRegistroChange = e => setFechaRegistro(e.target.value);
    const onRem1Change = e => setRem1(e.target.value);
    const onRem2Change = e => setRem2(e.target.value);
    const onRem3Change = e => setRem3(e.target.value);
    const onPresionchange = e => setPresion(e.target.value);
    const onTapaChange = e => setTapa(e.target.value);
    const onObservacionChange = e => setObservacion(e.target.value);

    const onSerieChange = e=>setSerie(e.target.value);
    const onDotChange = e => setDot(e.target.value);
    const onReencaucheChange = e=>setReencauche(e.target.value);
    const onProveedorChange = e=>setProveedor(e.target.value);
    const onCostoChange = e=>setCosto(e.target.value);
    const onOrdenCompraChange = e=>setOrdenCompra(e.target.value);
    const onModeloReencauche = e => setModeloReencauche(e.target.value);
    const onAnchoBandaChange = e => setAnchoBanda(e.target.value);

    useEffect(()=>{
        console.log(idneumatico);
        if(idneumatico!= 0){
            (async()=>{
                const data = await neumaticoPorId(idneumatico);
                console.log(data);
                if(data != null){
                    setCodigo(data.codigo);
                    setFechaRegistro(data.fecharegistro);
                    setSerie(data.serie);
                    setDot(data.dot);
                    setMarca(data.marca);
                    setDisenio(data.disenio);
                    setMedida(data.medida);
                    setRem1(data.rem1);
                    setRem2(data.rem2);
                    setRem3(data.rem3);
                    setReencauche(data.reencauche);
                    setProveedor(data.proveedor);
                    setOrdenCompra(data.ordencompra);
                    setCosto(data.costo);
                    setPresion(data.presion);
                    setCondicion(data.condicion);
                    setTapa(data.tapa);
                    setHistorial(data.historial);
                    setModeloReencauche(data.modeloreencauche);
                    setAnchoBanda(data.anchobanda);
                    setObservacion(data.observacion);
                }
            })();
        }
    },[])

    function validarFormulario(){
        let validar= true;
        if(codigo!='' && fecharegistro!='' && marca!='' && disenio!='' && medida!='' && rem1>0 && rem2>0 && rem3>0){
            validar = true;
        }else{
            validar=false;
        }
        return validar;
    }

    const handleGuargarNeumatico = e =>{
        e.preventDefault();
        const estado=1;//para disponible
        const subestado=1; //para estado disponible
        const activo=1;
        const usuario=usuarioctx.idusuario;
        

        if(tapa == false){
            setTapa(false);
        }
        else
        {
            setTapa(true);
        }
        
        if(validarFormulario()){            
        const data = {idneumatico,codigo,serie,dot,marca,disenio,medida,condicion,reencauche,fecharegistro,rem1,rem2,rem3,presion,tapa,proveedor,ordencompra,modeloreencauche,anchobanda,costo,observacion,estado,subestado,activo,usuario};
            (async()=>{
                const respuesta = await neumaticoGuardarPost(data);
                if(respuesta!=null){
                    if(respuesta.rest==1){
                        setValidarCodigo('');
                        setValidar(true);
                        navigate('/neumatico/');
                    }else{
                        setValidarCodigo(respuesta.error);

                    }
                }else
                {
                    console.log("acceso incorrecto vuelve a intentar");
                }
            })();
        }
        else{
            setValidar(false);
        }
    }


    return(
        <>      
        <Header />
        <Menu />
        <div>
        <div className="content-wrapper">
    {/* Content Header (Page header) */}
            <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Registrar neumático</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="/neumatico">Neumatico</a></li>
                    <li className="breadcrumb-item active">registrar neumático</li>
                    </ol>
                </div>{/* /.col */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
            </div>
    {/* /.content-header */}
    
    <section className="content">
      <div className="container-fluid">
        {/* Small boxes (Stat box) */}
        <div className="row">
        <div className="col-md-8">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Neumático</h3>
              </div>

              <form>
                <div className="card-body">
                    <div className="alert alert-danger alert-dismissible" hidden={validar} >
                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                    <h6><i className="icon fas fa-ban"></i>Debe llenar todos los campos para Guardar.</h6>                    
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Código de Fuego(*) <span className="text-danger">{validarcodigo}</span></label>
                                <input type="text" className="form-control" value={codigo} onChange={onCodigoChange} placeholder="Ingrese el codigo"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Fecha registro(*)</label>
                                <input type="date" className="form-control" value={fecharegistro} onChange={onFechaRegistroChange} placeholder="Fecha registro"/>
                            </div>
                        </div>  
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Serie Neumático</label>
                                <input type="text" className="form-control" value={serie} onChange={onSerieChange} placeholder="Serie"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>DOT</label>
                                <input type="text" className="form-control" value={dot} onChange={onDotChange} placeholder="dot"/>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Diseño(*)</label>
                                <input type="text" className="form-control" value={disenio} onChange={onDisenioChange} placeholder="Diseño"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Marca(*)</label>
                                <input type="text" className="form-control" value={marca} onChange={onMarcaChange} placeholder="Marca"/>
                            </div>
                        </div>                       
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Medida(*)</label>
                                <input type="text" className="form-control" value={medida} onChange={onMedidaChange} placeholder="Medida"/>
                            </div>
                        </div>   
                        <div className="col-md-6">
                        <label>Remanente(*)</label>
                            <div className="row">
                                <span className="col-sm-2"> Rem 1</span>
                                <input type="text" className="form-control col-sm-2" value={rem1} onChange={onRem1Change} placeholder="Rem 1" />
                                <span className="col-sm-2"> Rem 2</span>
                                <input type="text" className="form-control col-sm-2" value={rem2} onChange={onRem2Change} placeholder="Rem 2" />
                                <span className="col-sm-2"> Rem 3</span>
                                <input type="text" className="form-control col-sm-2"  value={rem3} onChange={onRem3Change} placeholder="Rem 3" />
                            </div>
                        </div>                                                
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Condición(*)</label>
                                <select className="form-control" value={condicion} onChange={onCondicionChange} >
                                    <option value={1}>Nuevo</option>
                                    <option value={2}>Reencauchado</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Nro. Reencauche</label>
                                <input type="number" className="form-control" value={reencauche} onChange={onReencaucheChange} placeholder="Nro de reencauche"/>
                            </div>
                        </div>                                       
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Modelo de reencauche</label>
                                <input type="text" className="form-control" value={modeloreencauche} onChange={onModeloReencauche} placeholder="Ingrese en modelo de reencauche"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Ancho de banda</label>
                                <input type="number" className="form-control" value={anchobanda} onChange={onAnchoBandaChange} placeholder="Ingrese el ancho de banda"/>
                            </div>
                        </div>                                       
                    </div>

                    <div className="row">
                        
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Proveedor</label>
                                <input type="text" className="form-control" value={proveedor} onChange={onProveedorChange} placeholder="Proveedor"/>
                            </div>
                        </div>  
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Orden de compra</label>
                                <input type="text" className="form-control" value={ordencompra} onChange={onOrdenCompraChange} placeholder="Orden de compra"/>
                            </div>
                        </div>  
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Costo</label>
                                <input type="number" step="0.01" min="0" className="form-control" value={costo} onChange={onCostoChange} placeholder="Costo"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Valvula con tapa</label>
                                <select className="form-control" value={tapa} onChange={onTapaChange}>
                                    <option value={1}>Si</option>
                                    <option value={0}>No</option>
                                </select>
                            </div>
                        </div>                        
                    </div>

                    <div className="row">
                        
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Presión</label>
                                <input type="text" className="form-control" value={presion} onChange={onPresionchange} placeholder="Presión" />
                            </div>
                        </div>                     
                        
                    </div>
                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Observación</label>
                                <input type="text" className="form-control" value={observacion} onChange={onObservacionChange} placeholder="Observacion" />
                            </div>
                        </div>                     
                        
                    </div>

                </div>
        

                <div className="card-footer">
                  <button type="submit" className="btn btn-primary" onClick={handleGuargarNeumatico} >Guardar</button>
                </div>
              </form>
            </div>
 

        

  

        </div>
        <div className="col-md-4">
            <div className="card card-secondary">
                <div className="card-header">
                    <h3 className="card-title">historial de Neumático</h3>
                </div>
            </div>
            <div className="card-body">
                {historial.map((item,index)=>{
                    if(item.idasignacion != null){
                        return(<div className="">                                                      
                                    <label className="text-success"><i className="icon fa fa-truck"></i> {item.fechacrea}</label> {item.descripcion}
                                </div>)
                    }else if(item.idreencauche != null){
                        return(<div className="">                                                      
                                    <label className="text-warning"><i className="icon fa fa-retweet"></i> {item.fechacrea}</label> {item.descripcion}
                                </div>)
                    }else if(item.idrecepcion != null){
                        return(<div className="">                                                      
                                    <label className="text-muted"><i className="icon fa fa-reply"></i> {item.fechacrea}</label> {item.descripcion}
                                </div>)
                    }else if(item.idscrap != null ){
                        return(<div className="">                                                      
                                    <label className="text-danger"><i className="icon fas fa-trash"></i> {item.fechacrea}</label> {item.descripcion}
                                </div>)
                    }else if(item.idinspeccion != null ){
                        return(<div className="">                                                      
                                    <label className="text-info"><i className="icon fas fa-search"></i> {item.fechacrea}</label> {item.descripcion}
                                </div>)
                    }else{
                        return(<div className="">                                                      
                                    <label className="text-primary"><i className="icon fa fa-info"></i> {item.fechacrea}</label> {item.descripcion}
                                </div>)
                    }
                        })}

                    

               

            </div>
            
        
            

        </div>

        
        
        </div>

      </div>{/* /.container-fluid */}
    </section>
  </div>
  
        </div>
        <Footer/>
        </>

    );
}