import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import useUsuario from "../../hooks/useUsuario";
import Select from "react-select";
import { observacionesGetAll, ordentrabajoPorId, ordenTrabajoRealizarPost } from "../../services/ordenTrabajo";
import { EmpleadoCombo, OperadorCombo } from "../../services/empleado";


export default function RealizarOrdenTrabajo(){
    const navigate = useNavigate();
    const[params] = useSearchParams();
    const {usuarioctx} = useUsuario();

    const [idordentrabajo,setIdOrdenTrabajo] =useState(params.get('idordentrabajo')?? 0);
    const [fechaordentrabajo,setFechaOrdenTrabajo] = useState('');
    const [unidad,setUnidad] = useState('');
    const [listaNeumaticoAsignado,setListaNeumaticoAsignado] = useState([]);
    const [listaNeumaticoDisponible,setListaNeumaticoDisponible] = useState([]);
    const [listaNeumaticoReencauche,setListaNeumaticoReencauche] = useState([]);
    const [listaNeumaticoScrap,setListaNeumaticoScrap] = useState([]);
    const [empleados,setEmpleados] = useState([]);
    const [fecharealizar,setFechaRealizar] = useState('');
    const [observacion,setObservacion] = useState('');

    const [idsolicitudtrabajo,setIdSolicitudTrabajo] = useState('');
    const [numerosolicitudtrabajo,setNumeroSolicitudTrabajo] = useState('');
    const [fechast,setFechaST] = useState('');
    const [kilometraje,setKilometraje] = useState('');
    const [operador,setOperador] = useState();
    const [cuenta,setCuenta] = useState('');
    const [idcuenta,setIdCuenta] = useState('');

    const [listaoperadores,setListaOperadores] = useState([]);
    const [listaobservaciones,setListaObservaciones] = useState([]);


    const [validar,setValidar] = useState(true);

    const onFechaRealizarChange = e => setFechaRealizar(e.target.value);
    const onObservacionChange = e => setObservacion(e.target.value);
    const onIdSolicitudTrabajoChange = e => setIdSolicitudTrabajo(e.target.value);
    const onNumeroSolicitudTrabajoChange = e => setNumeroSolicitudTrabajo(e.target.value);
    const onFechaSTChange = e => setFechaST(e.target.value);
    const onKilometrajeChange = e => setKilometraje(e.target.value);
    const onCuentaChange = e => setCuenta(e.target.value);

    const handleGuardarRealizarTrabajo = e =>{
        e.preventDefault();
        if(validarFormulario()){
            console.log('validado');
            const usuario = usuarioctx.idusuario;
            const detalle = [...listaNeumaticoDisponible,...listaNeumaticoAsignado,...listaNeumaticoReencauche,...listaNeumaticoScrap];
            const data = {idordentrabajo,fecharealizar,observacion,usuario,detalle,idsolicitudtrabajo,numerosolicitudtrabajo,fechast,kilometraje,operador,idcuenta,cuenta}
            listaobservaciones.map((item,index)=>{
                data[item.camporem]=item.remolcador;
                data[item.camposem]=item.semiremolque;
                data[item.campodesc]=item.descripcion;
            })
            console.log(data);

            (async()=>{
                const respuesta = await ordenTrabajoRealizarPost(data);
                if(respuesta==true){
                    navigate('/ordentrabajo/');
                }else
                {
                    console.log("acceso incorrecto vuelve a intentar");
                }
            })();
        }else{
            setValidar(false);
        }
    }

    function validarFormulario(){
        let validar=true;
        if(fecharealizar!=''){
            validar=true;
        }else{
            validar=false;
        }
        return validar;
    }
     
    const onBusquedaEmpleado = e =>{
        if(e!=''){
            console.log(e);
            (async()=>{
              const dataempleado = await EmpleadoCombo(e);
              console.log(dataempleado);
              setEmpleados(dataempleado);
          })();
        }
    }

    const onBusquedaOperador = e =>{
        if(e!=''){
            console.log(e);
            (async()=>{
              const dataempleado = await OperadorCombo(e);
              console.log(dataempleado);
              setListaOperadores(dataempleado);
          })();
        }
    }

    const onMecanicoChange = (e,index,tipo) =>{
        let actualizarlista = [];
        if(tipo == 1){
            actualizarlista=[...listaNeumaticoDisponible];
            actualizarlista[index].idempleado=e.value;
            console.log(actualizarlista);
            setListaNeumaticoDisponible(actualizarlista);
        }else if(tipo==2){
            actualizarlista=[...listaNeumaticoAsignado];
            actualizarlista[index].idempleado=e.value;
            console.log(actualizarlista);
            setListaNeumaticoAsignado(actualizarlista);
        }else if(tipo == 3){
            actualizarlista=[...listaNeumaticoReencauche];
            actualizarlista[index].idempleado=e.value;
            console.log(actualizarlista);
            setListaNeumaticoReencauche(actualizarlista);
        }else if(tipo == 4){
            actualizarlista=[...listaNeumaticoScrap];
            actualizarlista[index].idempleado=e.value;
            console.log(actualizarlista);
            setListaNeumaticoScrap(actualizarlista);
        }        
    }
    const onOperadorChange = (e)=>{
        console.log(e);
        //obtener cuneta y 
        setOperador(e.value);
        setCuenta(e.cuenta);
        setIdCuenta(e.idcuenta);
    }

    const onMarcarObservacion=(e,index,tipo)=>{
        let actualizarlista = [...listaobservaciones];
        if(tipo=='1'){
            actualizarlista[index].remolcador = !actualizarlista[index].remolcador;            
        }else if(tipo=='2'){
            actualizarlista[index].semiremolque = !actualizarlista[index].semiremolque;
        }
        console.log(actualizarlista)
        setListaObservaciones(actualizarlista);        
    }

    const onDescripcionObservacion=(e,index)=>{
        let actualizarlista = [...listaobservaciones];
        actualizarlista[index].descripcion = e.target.value;
        console.log(actualizarlista)
        setListaObservaciones(actualizarlista);        
    }
    
    const onTiempoChange = (e,index,tipo)=>{
        let actualizarlista =[];
        if(tipo==1){
            actualizarlista = [...listaNeumaticoDisponible];
            actualizarlista[index].tiempo=e.target.value;
            console.log(actualizarlista);
            setListaNeumaticoDisponible(actualizarlista);
        }else if(tipo == 2){
            actualizarlista = [...listaNeumaticoAsignado];
            actualizarlista[index].tiempo=e.target.value;
            console.log(actualizarlista);
            setListaNeumaticoAsignado(actualizarlista);
        }else if(tipo == 3){
            actualizarlista = [...listaNeumaticoReencauche];
            actualizarlista[index].tiempo=e.target.value;
            console.log(actualizarlista);
            setListaNeumaticoReencauche(actualizarlista);
        }else if(tipo == 4){
            actualizarlista = [...listaNeumaticoScrap];
            actualizarlista[index].tiempo=e.target.value;
            console.log(actualizarlista);
            setListaNeumaticoScrap(actualizarlista);
        }
    }

    useEffect(()=>{
        (async()=>{
            const data = await ordentrabajoPorId(idordentrabajo);
            console.log(data);
            
            setFechaOrdenTrabajo(data.fechacrea);
            setUnidad(data.vehiculo.placa);
            //listas
            setListaNeumaticoAsignado(data.detalle.filter((item)=>item.estado == 2));
            setListaNeumaticoDisponible(data.detalle.filter((item)=>item.estado == 1));
            setListaNeumaticoReencauche(data.detalle.filter((item)=>item.estado == 3));
            setListaNeumaticoScrap(data.detalle.filter((item)=>item.estado == 4));
        })();
        (async()=>{
            const lista = await observacionesGetAll();
            console.log(lista);
            setListaObservaciones(lista);           
            
        })();
    },[]);

    return(
        <>      
    <Header />
      <Menu />
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Orden trabajo</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/ordentrabajo">Lista orden trabajo</a></li>
                            <li className="breadcrumb-item active">Orden trabajo</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Orden trabajo</h3>
                                    </div>
                                    <form>
                                        <div className="card-body">
                                            <div className="alert alert-danger alert-dismissible" hidden={validar} >
                                            <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                                            <h6><i className="icon fas fa-ban"></i>Debe llenar todos los campos para Guardar.</h6>                    
                                            </div>
                                        <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Orden de trabajo Id</label>
                                                        <input type="text" value={idordentrabajo} className="form-control" placeholder="Ingrese la orden de compra" disabled={true}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Fecha OT</label>
                                                        <input type="text" value={fechaordentrabajo} className="form-control" placeholder="Ingrese la fecha" disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Unidad</label>
                                                        <input type="text" value={unidad} className="form-control" placeholder="Ingrese el requerimiento" disabled={true}/>
                                                    </div>
                                                </div>                                                
                                        </div>
                                        
                                        <div className="card-body">
                                        <label>Solicitud de trabajo - ST</label>
                                        <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Nro. ST(*)</label>
                                                        <input type="text" value={numerosolicitudtrabajo} onChange={onNumeroSolicitudTrabajoChange} className="form-control" placeholder="Ingrese la solicitud de trabajo"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Fecha ST(*)</label>
                                                        <input type="date" value={fechast} onChange={onFechaSTChange} className="form-control" placeholder="Ingrese la fecha"  />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Kilometraje/Hubodometro(*)</label>
                                                        <input type="text" value={kilometraje} onChange={onKilometrajeChange} className="form-control" placeholder="Ingrese el requerimiento" />
                                                    </div>
                                                </div>                                                
                                        </div>

                                        <div className="row">
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                        <label>Operador(*)</label>
                                                        <Select options={listaoperadores} defaultInputValue={''} onInputChange={onBusquedaOperador} onChange={(e)=>onOperadorChange(e)} placeholder="Seleccione operador"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Cuenta</label>
                                                        <input type="text" value={cuenta} className="form-control" onChange={onCuentaChange} placeholder="Cuenta" />
                                                    </div>
                                                </div>                                                
                                        </div>
                                        <div className="row">
                                            <h4 className="col-10 col-md-4">Neumático</h4>
                                            <h4 className="col-1 text-center">R</h4>
                                            <h4 className="col-1 text-center">S</h4>
                                            <h4 className="col-12 col-md-6">Descripción</h4> 
                                        </div>
                                        {listaobservaciones.map((item,index)=>(
                                            <div className="row">
                                                <span className="col-10 col-md-4 form-label">{item.etiqueta} : </span>
                                                <input type="checkbox" name={item.campo} className="col-md-1" checked={item.remolcador} onChange={(e) => onMarcarObservacion(e,index,1)}/>
                                                <input type="checkbox" name={item.campo} className="col-md-1" checked={item.semiremolque} onChange={(e) => onMarcarObservacion(e,index,2)}/>
                                                <span className="col-12 col-md-6">
                                                    <input type="text" name={item.descripcion} className="form-control" value={item.descripcion} onChange={(e)=>onDescripcionObservacion(e,index)}/>
                                                </span><hr className="m-1"/>
                                            </div>

                                        ))}
                                        
                                        
                                        
                                        

                                                
                                        </div>

                                    
                                        <div className="row" hidden={(listaNeumaticoAsignado.length > 0)?false:true}>
                                                <div className="col-md-12">
                                                <label>Lista de neumáticos Asigandos a la unidad</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="bg-success">
                                                                <th style={{width:"50px"}}>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Condición</th>
                                                                <th style={{width:"90px"}}>Medida</th>                                          
                                                                <th style={{width:"90px"}}>Posición</th>
                                                                <th style={{width:"350px"}}>Mecánico</th>
                                                                <th style={{width:"130px"}}>Tiempo (min)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listaNeumaticoAsignado.map((neumatico,index) => (
                                                            <tr className="">
                                                                <td>{neumatico.idneumatico}</td>
                                                                <td>{neumatico.codigo}</td>
                                                                <td>{neumatico.marca}</td>
                                                                <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                <td>{neumatico.medida}</td>
                                                                <td>{(neumatico.posicion > 200)?<span className="text-danger">Repuesto</span> : neumatico.posicion}</td>
                                                                <td><Select options={empleados} defaultInputValue={''} data-index={index} onInputChange={onBusquedaEmpleado} onChange={(e)=>onMecanicoChange(e,index,2)} /></td>
                                                                <td><input type="number" className="form-control" value={neumatico.tiempo} onChange={(e)=>onTiempoChange(e,index,2)} /></td>
                                                            </tr>
                                                            )
                                                            )}

                                                        </tbody>
                                                    </table>
                                                </div>
                                        </div>
                                        <div className="row" hidden={(listaNeumaticoDisponible.length > 0)?false:true}>
                                                <div className="col-md-12">
                                                <label>Lista de neumáticos Retirados para Disponible</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="bg-primary">
                                                                <th style={{width:"50px"}}>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Condición</th>
                                                                <th style={{width:"90px"}}>Medida</th>                                          
                                                                <th style={{width:"90px"}}>Posición</th>
                                                                <th style={{width:"350px"}}>Mecánico</th>
                                                                <th style={{width:"130px"}}>Tiempo (min)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listaNeumaticoDisponible.map((neumatico,index) => {
                                                            if(neumatico.estado==1){
                                                            return(
                                                            <tr>
                                                                <td>{neumatico.idneumatico}</td>
                                                                <td>{neumatico.codigo}</td>
                                                                <td>{neumatico.marca}</td>
                                                                <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                <td>{neumatico.medida}</td>
                                                                <td>{neumatico.posicion}</td>
                                                                <td><Select  options={empleados} defaultInputValue={''} onInputChange={onBusquedaEmpleado} onChange={(e)=>onMecanicoChange(e,index,1)}/></td>
                                                                <td><input type="number" className="form-control" value={neumatico.tiempo} onChange={(e)=>onTiempoChange(e,index,1)}/></td>
                                                            </tr>
                                                            )
                                                            }
                                                        })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                        </div>
                                        <div className="row" hidden={(listaNeumaticoReencauche.length > 0)? false : true}>
                                                <div className="col-md-12">
                                                <label>Lista de neumáticos Retirados para Reencauche</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="bg-warning">
                                                                <th style={{width:"50px"}}>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Condición</th>
                                                                <th style={{width:"90px"}}>Medida</th>                                          
                                                                <th style={{width:"90px"}}>Posición</th>
                                                                <th style={{width:"350px"}}>Mecánico</th>
                                                                <th style={{width:"130px"}}>Tiempo (min)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listaNeumaticoReencauche.map((neumatico,index) =>(
                                                            <tr>
                                                                <td>{neumatico.idneumatico}</td>
                                                                <td>{neumatico.codigo}</td>
                                                                <td>{neumatico.marca}</td>
                                                                <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                <td>{neumatico.medida}</td>
                                                                <td>{neumatico.posicion}</td>
                                                                <td><Select  options={empleados} defaultInputValue={''} onInputChange={onBusquedaEmpleado} onChange={(e)=>onMecanicoChange(e,index,3)}/></td>
                                                                <td><input type="number" className="form-control" value={neumatico.tiempo} onChange={(e)=>onTiempoChange(e,index,3)}/></td>
                                                            </tr>
                                                            )
                                                            )}

                                                        </tbody>
                                                    </table>
                                                </div>
                                        </div>
                                        <div className="row" hidden={(listaNeumaticoScrap.length > 0)? false : true}>
                                                <div className="col-md-12">
                                                <label>Lista de neumáticos Retirados para Scrap</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="bg-danger">
                                                                <th style={{width:"50px"}}>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Condición</th>
                                                                <th style={{width:"90px"}}>Medida</th>                                          
                                                                <th style={{width:"90px"}}>Posición</th>
                                                                <th style={{width:"350px"}}>Mecánico</th>
                                                                <th style={{width:"130px"}}>Tiempo (min)</th>                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listaNeumaticoScrap.map((neumatico,index) =>(
                                                            <tr>
                                                                <td>{neumatico.idneumatico}</td>
                                                                <td>{neumatico.codigo}</td>
                                                                <td>{neumatico.marca}</td>
                                                                <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                <td>{neumatico.medida}</td>
                                                                <td>{neumatico.posicion}</td>
                                                                <td><Select  options={empleados} defaultInputValue={''} onInputChange={onBusquedaEmpleado} onChange={(e)=>onMecanicoChange(e,index,4)}/></td>
                                                                <td><input type="number" className="form-control" value={neumatico.tiempo} onChange={(e)=>onTiempoChange(e,index,4)}/></td>
                                                            </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                        </div>
                                    


                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Fecha de realizacion(*)</label>
                                                        <input type="date" value={fecharealizar} onChange={onFechaRealizarChange} className="form-control" placeholder="Ingrese la fecha de realización"  />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                        <label>Observación(*)</label>
                                                        <input type="text" value={observacion} onChange={onObservacionChange} className="form-control" placeholder="Ingrese la observación" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                
                                                <button type="button" onClick={handleGuardarRealizarTrabajo} className="btn btn-success" >Guardar</button>
                                            </div>
                                            
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    
                    </div>
                </section>

            </div>

        </div>
        <Footer/>
        </>
    );
}