import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import useUsuario from "../../hooks/useUsuario";
import { neumaticoParaReencauche } from "../../services/neumaticos";
import { bloquereencaucheGuardarPost } from "../../services/reencauche";


export default function EnvioReencauche(){
    const {usuarioctx} = useUsuario();
    const navigate = useNavigate();
    const [listaNeumatico,setListaNeumatico] = useState([]);
    const [fecha,setFecha] = useState('');
    const [ordencompra,setOrdenCompra] = useState('');
    const [requerimiento,setRequerimiento] = useState('');
    const [proveedor,setProveedor] = useState('');
    
    const [validar,setValidar] = useState(true);

    const onFechaChange = e => setFecha(e.target.value);
    const onOrdenCompraChange = e => setOrdenCompra(e.target.value);
    const onRequerimientoChange = e => setRequerimiento(e.target.value);
    const onProveedorChange = e => setProveedor(e.target.value);
    
    const handleQuitarNeumatico = (id) =>{
        let actualizarlista = [...listaNeumatico];
        actualizarlista = actualizarlista.filter((item)=>item.idneumatico != id)
        setListaNeumatico(actualizarlista);
        console.log(actualizarlista);
    }

    const handleGuardarEnvioReencauche = e =>{
        e.preventDefault();
        
        if(validarFormulario()){
            const activo = 1;
            const usuario = usuarioctx.idusuario;

            const data = {fecha,ordencompra,requerimiento,proveedor,activo,usuario,neumaticos:listaNeumatico};
            (async()=>{
                const respuesta = await bloquereencaucheGuardarPost(data);
                if(respuesta==true){
                    navigate('/reencauche/');
                }else
                {
                    console.log("acceso incorrecto vuelve a intentar");
                }
            })();   
        }else{
            setValidar(false);
        }

    }

    function validarFormulario(){
        let validar=true;
        if(fecha!='' && ordencompra!='' && requerimiento!='' && proveedor!='' && listaNeumatico.length > 0 ){
            validar=true;
        }else{
            validar=false;
        }
        return validar;
    }

    useEffect(()=>{
        (async()=>{
            const data = await neumaticoParaReencauche();            
            setListaNeumatico(data);
        })();
    },[]);

    return(
        <>      
        <Header />
        <Menu />
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Envio a reencauche</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/reencauche">Reencauche</a></li>
                            <li className="breadcrumb-item active">Envio a reencauche</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Envio de Neumatico</h3>
                                    </div>
                                    <form>
                                        <div className="card-body">
                                            <div className="alert alert-danger alert-dismissible" hidden={validar} >
                                            <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                                            <h6><i className="icon fas fa-ban"></i>Debe llenar todos los campos para Guardar.</h6>                    
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Fecha(*)</label>
                                                        <input type="date" value={fecha} className="form-control" onChange={onFechaChange} placeholder="Ingrese la fecha" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Orden de Compra(*)</label>
                                                        <input type="text" value={ordencompra} className="form-control" onChange={onOrdenCompraChange} placeholder="Ingrese la orden de compra"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Requerimiento(*)</label>
                                                        <input type="text" value={requerimiento} className="form-control" onChange={onRequerimientoChange} placeholder="Ingrese el requerimiento"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Proveedor(*)</label>
                                                        <input type="text" value={proveedor} className="form-control" onChange={onProveedorChange} placeholder="Ingrese el proveedor"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                <label>Lista de neumáticos</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Condición</th>
                                                                <th>Medida</th>                                          
                                                                <th style={{width:"100px"}}>Quitar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listaNeumatico.map((neumatico) => (
                                                            <tr>
                                                                <td>{neumatico.idneumatico
                                                                }</td>
                                                                <td>{neumatico.codigo}</td>
                                                                <td>{neumatico.marca}</td>
                                                                <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                <td>{neumatico.medida}</td>
                                                                <td><button type="button" className="btn-xs btn-danger" onClick={()=>handleQuitarNeumatico(neumatico.idneumatico)}><i className="fa fa-times" aria-hidden="true"></i></button></td>
                                                            </tr>
                                                        ))}

                                                        </tbody>
                                                    </table>
                                                    <button className="btn btn-success" onClick={handleGuardarEnvioReencauche}>Guardar</button>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    
                    </div>
                </section>

            </div>

        </div>
        <Footer/>
        </>
    );
}