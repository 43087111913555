import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import { neumaticoParaReencauche } from "../../services/neumaticos";
import { observacionesGetAll, ordentrabajoPorId, ordenTrabajoRealizarPost } from "../../services/ordenTrabajo";


export default function VerOrdenTrabajo(){
    const navigate = useNavigate();
    const[params] = useSearchParams();

    const [idordentrabajo,setIdOrdenTrabajo] =useState(params.get('idordentrabajo')?? 0);
    const [fechaordentrabajo,setFechaOrdenTrabajo] = useState('');
    const [unidad,setUnidad] = useState('');

    const [fecharealizar,setFechaRealizar] = useState('');
    const [observacion,setObservacion] = useState('');

    const[numerosolicitud,setNumerosolicitud] = useState('');
    const[fechast,setFechaST] = useState('');


    const [listaNeumaticoAsignado,setListaNeumaticoAsignado] = useState([]);
    const [listaNeumaticoDisponible,setListaNeumaticoDisponible] = useState([]);
    const [listaNeumaticoReencauche,setListaNeumaticoReencauche] = useState([]);
    const [listaNeumaticoScrap,setListaNeumaticoScrap] = useState([]);
    const [kilometraje,setKilometraje] = useState('');
    const [operador,setOperador] = useState();
    const [cuenta,setCuenta] = useState('');
    const [idcuenta,setIdCuenta] = useState('');
    const [listaobservaciones,setListaObservaciones] = useState([]);


    useEffect(()=>{
        (async()=>{
            const data = await ordentrabajoPorId(idordentrabajo);
            console.log(data);
            setFechaOrdenTrabajo(data.fechacrea);
            setUnidad(data.vehiculo.placa);
            setFechaRealizar(data.fecharealizacion);
            setObservacion(data.observacion);
            setNumerosolicitud(data.solicitudtrabajo?.numerosolicitud);
            setFechaST(data.solicitudtrabajo?.fechasolicitud);
            setKilometraje(data.solicitudtrabajo?.kilometraje);
            if(data.solicitudtrabajo?.empleado!= undefined){
                setOperador(data.solicitudtrabajo?.empleado?.numerodocumento+" - " +data.solicitudtrabajo?.empleado?.nombres+" "+ data.solicitudtrabajo?.empleado?.apellidopaterno+" "+data.solicitudtrabajo?.empleado?.apellidomaterno);
            }
                
            setCuenta(data.solicitudtrabajo?.cuenta); 
            //listas
            setListaNeumaticoAsignado(data.detalle.filter((item)=>item.estado == 2));
            setListaNeumaticoDisponible(data.detalle.filter((item)=>item.estado == 1));
            setListaNeumaticoReencauche(data.detalle.filter((item)=>item.estado == 3));
            setListaNeumaticoScrap(data.detalle.filter((item)=>item.estado == 4));

            const lista = await observacionesGetAll();
            console.log(lista);
            lista.map((item,index)=>{
                item.remolcador = (data.solicitudtrabajo?.[item.camporem]==null)?false:data.solicitudtrabajo?.[item.camporem];
                item.semiremolque = (data.solicitudtrabajo?.[item.camposem]==null)?false:data.solicitudtrabajo?.[item.camposem];
                item.descripcion = (data.solicitudtrabajo?.[item.campodesc]==null)?'':data.solicitudtrabajo?.[item.campodesc];
            })

            setListaObservaciones(lista);

        })();
    },[]);

    return(
        <>      
        <Header />
        <Menu />
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Ver Orden Trabajo</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/ordentrabajo">Lista orden trabajo</a></li>
                            <li className="breadcrumb-item active">Ver orden trabajo</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Ver orden trabajo</h3>
                                    </div>
                                    <form>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Orden de trabajo Id</label>
                                                        <input type="text" value={idordentrabajo} className="form-control" placeholder="Ingrese la orden de compra" disabled={true}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Fecha OT</label>
                                                        <input type="text" value={fechaordentrabajo} className="form-control" placeholder="Ingrese la fecha" disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Unidad</label>
                                                        <input type="text" value={unidad} className="form-control" placeholder="Ingrese el requerimiento" disabled={true}/>
                                                    </div>
                                                </div>                                                
                                            </div>

                                            <div className="card-body">
                                        <label>Solicitud de trabajo - ST</label>
                                        <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Nro. ST</label>
                                                        <input type="text" value={numerosolicitud} className="form-control" placeholder="Ingrese la solicitud de trabajo" disabled={true}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Fecha ST</label>
                                                        <input type="date" value={fechast} className="form-control" placeholder="Ingrese la fecha" disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Kilometraje</label>
                                                        <input type="text" value={kilometraje} className="form-control" placeholder="Ingrese el requerimiento" disabled={true} />
                                                    </div>
                                                </div>                                                
                                        </div>

                                        <div className="row">
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                        <label>Operador</label>
                                                        <input className="form-control" placeholder="Seleccione operador" value={operador}  disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Cuenta</label>
                                                        <input type="text" value={cuenta} className="form-control" placeholder="Cuenta" disabled={true}/>
                                                    </div>
                                                </div>                                                
                                        </div>
                                        <div className="row">
                                            <h4 className="col-10 col-md-4">Neumático</h4>
                                            <h4 className="col-1 text-center">R</h4>
                                            <h4 className="col-1 text-center">S</h4>
                                            <h4 className="col-12 col-md-6">Descripción</h4> 
                                        </div>
                                        {listaobservaciones.map((item,index)=>(
                                            <div className="row">
                                                <span className="col-10 col-md-4 form-label">{item.etiqueta} : </span>
                                                <input type="checkbox" name={item.campo} className="col-md-1" checked={item.remolcador} />
                                                <input type="checkbox" name={item.campo} className="col-md-1" checked={item.semiremolque} />
                                                <span className="col-12 col-md-6">
                                                    <input type="text" name={item.descripcion} className="form-control" value={item.descripcion} />
                                                </span><hr className="m-1"/>
                                            </div>

                                        ))}
                                        
                                        
                                        
                                        

                                                
                                        </div>
                                            


                                            <div className="row" hidden={(listaNeumaticoAsignado.length > 0)?false:true}>
                                                <div className="col-md-12">
                                                <label>Lista de neumáticos Asigandos a la unidad</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="bg-success">
                                                                <th style={{width:"50px"}}>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Condición</th>
                                                                <th style={{width:"90px"}}>Medida</th>                                          
                                                                <th style={{width:"90px"}}>Posición</th>
                                                                <th style={{width:"350px"}}>Mecánico</th>
                                                                <th style={{width:"130px"}}>Tiempo (min)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listaNeumaticoAsignado.map((neumatico,index) => {
                                                            if(neumatico.posicion>200){
                                                                return(
                                                                    <tr className="">
                                                                    <td>{neumatico.idneumatico}</td>
                                                                    <td>{neumatico.codigo}</td>
                                                                    <td>{neumatico.marca}</td>
                                                                    <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                    <td>{neumatico.medida}</td>
                                                                    <td className="text-danger">Repuesto</td>
                                                                    <td>{neumatico.empleado?.numerodocumento} - {neumatico.empleado?.nombres} {neumatico.empleado?.apellidopaterno} {neumatico.empleado?.apellidomaterno}</td>
                                                                    <td>{neumatico.tiempo}</td>
                                                                </tr>
                                                                );
                                                            }else{
                                                                return(
                                                                    <tr className="">
                                                                    <td>{neumatico.idneumatico}</td>
                                                                    <td>{neumatico.codigo}</td>
                                                                    <td>{neumatico.marca}</td>
                                                                    <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                    <td>{neumatico.medida}</td>
                                                                    <td>{neumatico.posicion}</td>
                                                                    <td>{neumatico.empleado?.numerodocumento} - {neumatico.empleado?.nombres} {neumatico.empleado?.apellidopaterno} {neumatico.empleado?.apellidomaterno}</td>
                                                                    <td>{neumatico.tiempo}</td>
                                                                </tr>
                                                                );
                                                            }
                                                        }
                                                            )}

                                                        </tbody>
                                                    </table>
                                                </div>
                                        </div>
                                        <div className="row" hidden={(listaNeumaticoDisponible.length > 0)?false:true}>
                                                <div className="col-md-12">
                                                <label>Lista de neumáticos Retirados para Disponible</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="bg-primary">
                                                                <th style={{width:"50px"}}>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Condición</th>
                                                                <th style={{width:"90px"}}>Medida</th>                                          
                                                                <th style={{width:"90px"}}>Posición</th>
                                                                <th style={{width:"350px"}}>Mecánico</th>
                                                                <th style={{width:"130px"}}>Tiempo (min)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listaNeumaticoDisponible.map((neumatico,index) => {
                                                            if(neumatico.estado==1){
                                                            return(
                                                            <tr>
                                                                <td>{neumatico.idneumatico}</td>
                                                                <td>{neumatico.codigo}</td>
                                                                <td>{neumatico.marca}</td>
                                                                <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                <td>{neumatico.medida}</td>
                                                                <td>{neumatico.posicion}</td>
                                                                <td>{neumatico.empleado?.numerodocumento} - {neumatico.empleado?.nombres} {neumatico.empleado?.apellidopaterno} {neumatico.empleado?.apellidomaterno}</td>
                                                                <td>{neumatico.tiempo} </td>
                                                            </tr>
                                                            )
                                                            }
                                                        })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                        </div>
                                        <div className="row" hidden={(listaNeumaticoReencauche.length > 0)? false : true}>
                                                <div className="col-md-12">
                                                <label>Lista de neumáticos Retirados para Reencauche</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="bg-warning">
                                                                <th style={{width:"50px"}}>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Condición</th>
                                                                <th style={{width:"90px"}}>Medida</th>                                          
                                                                <th style={{width:"90px"}}>Posición</th>
                                                                <th style={{width:"350px"}}>Mecánico</th>
                                                                <th style={{width:"130px"}}>Tiempo (min)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listaNeumaticoReencauche.map((neumatico,index) =>(
                                                            <tr>
                                                                <td>{neumatico.idneumatico}</td>
                                                                <td>{neumatico.codigo}</td>
                                                                <td>{neumatico.marca}</td>
                                                                <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                <td>{neumatico.medida}</td>
                                                                <td>{neumatico.posicion}</td>
                                                                <td>{neumatico.empleado?.numerodocumento} - {neumatico.empleado?.nombres} {neumatico.empleado?.apellidopaterno} {neumatico.empleado?.apellidomaterno}</td>
                                                                <td>{neumatico.tiempo} </td>
                                                            </tr>
                                                            )
                                                            )}

                                                        </tbody>
                                                    </table>
                                                </div>
                                        </div>
                                        <div className="row" hidden={(listaNeumaticoScrap.length > 0)? false : true}>
                                                <div className="col-md-12">
                                                <label>Lista de neumáticos Retirados para Scrap</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="bg-danger">
                                                                <th style={{width:"50px"}}>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Condición</th>
                                                                <th style={{width:"90px"}}>Medida</th>                                          
                                                                <th style={{width:"90px"}}>Posición</th>
                                                                <th style={{width:"350px"}}>Mecánico</th>
                                                                <th style={{width:"130px"}}>Tiempo (min)</th>                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listaNeumaticoScrap.map((neumatico,index) =>(
                                                            <tr>
                                                                <td>{neumatico.idneumatico}</td>
                                                                <td>{neumatico.codigo}</td>
                                                                <td>{neumatico.marca}</td>
                                                                <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                <td>{neumatico.medida}</td>
                                                                <td>{neumatico.posicion}</td>
                                                                <td>{neumatico.empleado?.numerodocumento} - {neumatico.empleado?.nombres} {neumatico.empleado?.apellidopaterno} {neumatico.empleado?.apellidomaterno}</td>
                                                                <td>{neumatico.tiempo} </td>
                                                            </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                        </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Fecha de realizacion</label>
                                                        <input type="text" value={fecharealizar} className="form-control" placeholder="Ingrese la fecha de realización" disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                        <label>Observación</label>
                                                        <input type="text" value={observacion} className="form-control" placeholder="Ingrese la observación" disabled={true} />
                                                    </div>
                                                </div>
                                            </div>                                            
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    
                    </div>
                </section>

            </div>

        </div>
        <Footer/>
        </>
    );
}