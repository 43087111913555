import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import useUsuario from "../../hooks/useUsuario";
import { neumaticoBajaPost, neumaticoPorId } from "../../services/neumaticos";

export default function RegistrarScrap(){
    const navigate = useNavigate();
    const[params] = useSearchParams();
    const { usuarioctx } = useUsuario();

    const [idneumatico,setIdNeumatico] = useState(params.get('idneumatico')?? 0);
    const [codigo,setCodigo] = useState('');
    const [marca,setMarca] = useState('');
    const [disenio,setDisenio] = useState('');
    const [medida,setMedida] = useState('');
    const [reen,setReen] = useState('');
    const [cocada,setCocada] = useState();
    const [condicion,setCondicion] = useState(1);
    const [anio,setAnio] = useState('');
    const [fechabaja,setFechaBaja] = useState('');
    const [observacion,setObservacion] = useState('');
    const [validar,setValidar] = useState(true);

    const onFechaBajaChange = e => setFechaBaja(e.target.value);
    const onObservacionChange = e => setObservacion(e.target.value);
    
    const handleBajaNeumatico = e =>{
        e.preventDefault();

        if(validarFormulario()){


        const usuario=usuarioctx.idusuario;

        const data = {idneumatico,fechabaja,observacion,usuario};
        (async()=>{
            const respuesta = await neumaticoBajaPost(data);
            if(respuesta!=null){               
                navigate('/scrap/');
            }else
            {
                console.log("acceso incorrecto vuelve a intentar");
            }

        })();
        }
        else{
            setValidar(false);
        }
    }

    function validarFormulario(){
        let validar=true;
        if(fechabaja!='' && observacion != ''){
            validar=true;
        }else{
            validar=false;
        }
        return validar;
    }

    useEffect(()=>{
        console.log(idneumatico);
        if(idneumatico!= 0){
            (async()=>{
                const data = await neumaticoPorId(idneumatico);
                console.log(data);
                if(data != null){
                    setCodigo(data.codigo);
                    setMarca(data.marca);
                    setDisenio(data.disenio);
                    setMedida(data.medida);
                    setReen(data.reen);
                    setCocada(data.cocada);
                    setCondicion(data.condicion);
                    setAnio(data.anio);
                }
            })();
        }
    },[])

    return(
        <>      
<Header />
      <Menu />
        <div>
            <div className="content-wrapper">
    
                <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Registrar scrap</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/scrap">Scrap</a></li>
                        <li className="breadcrumb-item active">Registrar scrap</li>
                        </ol>
                    </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Registro scrap</h3>
                                </div>
                                <form>
                                    <div className="card-body">
                                        <div className="alert alert-danger alert-dismissible" hidden={validar} >
                                            <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                                            <h6><i className="icon fas fa-ban"></i>Debe llenar todos los campos para Guardar.</h6>                    
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Código</label>
                                                    <input type="text" value={codigo} className="form-control" id="codigo" placeholder="Ingrese el codigo" disabled/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Marca</label>
                                                    <input type="text" value={marca} className="form-control" id="marca" placeholder="Marca" disabled/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Diseño</label>
                                                    <input type="text" value={disenio} className="form-control" id="diseno" placeholder="Diseño" disabled/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Medida</label>
                                                    <input type="text" value={medida} className="form-control" id="medida" placeholder="Medida" disabled/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Reen</label>
                                                    <input type="text" value={reen} className="form-control" id="reen" placeholder="Reen" disabled/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Cocada</label>
                                                    <input type="text" value={cocada} className="form-control" id="cocada" placeholder="Cocada" disabled/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Condición</label>
                                                    <select className="form-control" value={condicion} id="estado" disabled>
                                                        <option value={1}>Nuevo</option>
                                                        <option value={2}>Reencauchado</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Año</label>
                                                    <input type="text" value={anio} className="form-control" id="anio" placeholder="Año" disabled/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Fecha baja</label>
                                                    <input type="date" value={fechabaja} className="form-control" id="fechabaja" placeholder="Fecha baja" onChange={onFechaBajaChange}/>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Observacion</label>
                                                    <input type="text" className="form-control" value={observacion} placeholder="Ingrese una observación" onChange={onObservacionChange}/>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-footer">
                                        <button type="button" className="btn btn-primary"onClick={handleBajaNeumatico}>Guardar</button>
                                    </div>
                                </form>
                            </div>

                            </div>
                        </div>
                    </div>

                </section>


            </div>

        </div>
        <Footer/>
</>
    );
}