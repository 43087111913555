import logo from './logo.svg';
import './App.css';
import Header from './component/Header';
import Menu from './component/Menu';

import Footer from './component/Footer';
import { BrowserRouter as Router,Routes,Route, Navigate } from 'react-router-dom';
import Home from './modulos/home/Home';
import Neumatico from './modulos/neumaticos/Neumatico';
import RegistrarNeumatico from './modulos/neumaticos/RegistrarNeumatico';
import Asigancion from './modulos/asignacion/Asignacion';
import OrdenTrabajo from './modulos/asignacion/OrdenTrabajo';
import Reencauche from './modulos/reencauche/Reencauche';
import EnvioReencauche from './modulos/reencauche/EnvioReencauche';
import BloquesReencauche from './modulos/reencauche/BloquesReencauche';
import Recepcion from './modulos/recepcion/Recepcion';
import RecepcionNeumatico from './modulos/recepcion/RecepcionNeumatico';
import RegistrarRecepcion from './modulos/recepcion/RegistrarRecepcion';
import Scrap from './modulos/scrap/Scrap';
import RegistrarScrap from './modulos/scrap/RegistrarScrap';
import RegistrarInspeccion from './modulos/inspeccion/RegistrarInspeccion';
import Inspeccion from './modulos/inspeccion/Inspeccion';
import VerInspeccion from './modulos/inspeccion/VerInspeccion';
import VerRecepcion from './modulos/recepcion/VerRecepcion';
import VerBloque from './modulos/reencauche/VerBloque';
import RealizarOrdenTrabajo from './modulos/asignacion/RealizarOrdenTrabajo';
import VerOrdenTrabajo from './modulos/asignacion/VerOrdenTrabajo';
import Login from './modulos/login/Login';
import UsuarioContext, { ProviderUsuario } from './context/UsuarioContext';
import { useEffect, useState } from 'react';
import SinPagina from './modulos/home/404';
import { CSVLink } from "react-csv";
import EditarOrdenTrabajo from './modulos/asignacion/EditarOrdenTrabajo';
import VerNeumatico from './modulos/neumaticos/VerNeumatico';


function App() {
  const [usuarioctx,setUsuarioCtx] = useState(JSON.parse(localStorage.getItem('usuarioctx')) ?? null);

  const handleUsuarioCtxChange = (objUsuario) =>{
    setUsuarioCtx(objUsuario);
    localStorage.setItem('usuarioctx', JSON.stringify(objUsuario));
  }

  return (
    <div className="wrapper">
      <UsuarioContext.Provider value={{usuarioctx,handleUsuarioCtxChange}}>
      <Router>
        <Routes>
          <Route path='/' element={<Navigate to={"/home/"} />} />
          <Route path='/home' element={<Home/>}/>
          <Route path='/neumatico' element={<Neumatico /> } />
          <Route path='/neumatico/registrar' element={<RegistrarNeumatico />}/>
          <Route path='/neumatico/ver' element={<VerNeumatico />}/>

          <Route path='/asignacion' element={<Asigancion/>} />
          <Route path='/ordentrabajo' element={<OrdenTrabajo/>} />
          <Route path='/realizarordentrabajo' element={<RealizarOrdenTrabajo />}/>
          <Route path='/verordentrabajo/' element={<VerOrdenTrabajo/>} />
          <Route path='/editarordentrabajo/' element={<EditarOrdenTrabajo />}/>

          <Route path='/reencauche' element={<Reencauche />} />
          <Route path='/reencauche/envio' element={<EnvioReencauche />} />
          <Route path='/reencauche/ver' element={<VerBloque />} />
          <Route path='/bloquereencauche' element={<BloquesReencauche />} />

          <Route path='/recepcion' element={<Recepcion />} />
          <Route path='/recepcionneumatico' element={<RecepcionNeumatico />} />
          <Route path='/recepcion/ver' element={<VerRecepcion />} />
          <Route path='/registrorecepcion' element={<RegistrarRecepcion />}/>

          <Route path='/scrap' element={<Scrap/>} />
          <Route path='/scrap/registro' element={<RegistrarScrap/>}/>

          <Route path='/inspeccion' element={<Inspeccion/>}/>
          <Route path='/inspeccion/ver' element={<VerInspeccion/>}/>
          <Route path='/inspeccion/registro' element={<RegistrarInspeccion/>}/>

          <Route path='/login' element={<Login/>} />
          <Route path='*' element={<SinPagina/>} />

          
        </Routes>
        
      </Router>
      </UsuarioContext.Provider>
      


    </div>
  );
}

export default App;
